.header_bar {
  background-color: #f3f8f9;
  border-bottom: 2px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 100;
}

.showShadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header_items_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 2rem;
}

.header_item {
  font-weight: 500;
  padding: 0 1rem;
}

.header_link {
  text-decoration: none !important;
  color: black !important;
}

@media (max-width: 600px) {
  .header_bar {
    background-color: #f3f8f9;
    border-bottom: 2px solid #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  /* .header_logo_wrap {
    padding-right: 1rem;
  } */
  .header_items_wrap {
    display: none;
  }
}

@media (min-width: 601px) {
  .header_logo_wrap {
    padding-left: 1rem;
  }
}
